export const sectionList = [
  'columnListWith2Items', // contains column structure for having 2 items of list in one row.
  'headerWithParagraph', // Contains heading with paragraph,links, ordered,unordered list
  'imageWithPath', //contains Images , Heading with Link.
  'contentListWithGreyBackground', // contains contentlist with grey background
  'contentWithImageLeft', //contains image of 25% on left side
  'contentListColumnGreyBackground',
  'richTextWithGreyCardRight',
  'pageHeader',
  'headerWithImage', //contains image with header
  'richTextImageParagraph',
  'contentListWith3ItemsGreyBackground',
  'contentListColumn',
  'richTextWithImageRight',
  'imageFullWidth',
  'imageHeightLimited',
  'richTextImageParagraphLeftAligned',
  'imageWithGreyCard',
  'imageWithPathSquare',
  'contentListWith3ItemsGreyBackgroundLeftAligned',
  'headerWithParagraphArrowLink',
  'contentOneThird',
  'contentOneThirdNews',
  'columnListRatioOneThirdTwoThird',
  'contentTwoThird',
  'commonAccordionCL',
  'columnList4Items',
  'headerWithParagraphTwoThird',
  'richTextWithGreyCardRightWithoutAnimation',
  'contentListColumn3Items',
  'communityNews',
  'marketoForm',
  'richTextWithCenterDiv',
];

export const FormTypes = ['marketoForm', 'slpMarketoForm', 'searchSection'];
